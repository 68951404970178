//@import "~antd/dist/antd.less"  ;
 
@primary-color: #F5717F;   
@link-color: #F5717F;   
@border-radius-base: 7px;   
@heading-color: #373737;       
@font-size-base: 15px;    
@main-bkg : #E9E3E3;   

.ant-layout-header { position: fixed;  
    z-index: 1000; 
    width: 100% !important;  
    background: #FFFFFF !important; }
.ant-layout-content { margin-top: 70px !important; min-height: 600px;}
.page-container { background: @main-bkg;}  
.white-bg {background-color: #FFFFFF; }
.green-bg {background-color: #5FCE85;}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after { border-bottom: 7px solid @primary-color !important;}
.ant-menu-horizontal {  
    border-bottom: 0px solid #f0f0f0;
    padding-top: 8px 0px; 
    .ant-menu-item{ 
        font-size: 16px;   
    }     
    .anticon{   
        font-size: 25px !important;  
    }
    button {
        margin-bottom: 8px;  
    } 
} 
.ant-btn {
    border: none !important;
}
.cool-radius { border-radius:0px 12px 12px 12px; padding: 12px;} 

.shad { box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px !important;  }
.coolBox1 {
    position:absolute;
    bottom: 0;
    right: 0;
    border-radius: 8px;
    padding: 15px;
    b {    
        display: inline-block;
        background: #FFDE08;
        color: #FE496C;
        font-weight: bolder;
        padding: 7px 12px; 
        font-size: 17px; 
    }   
    p{
        color:#FFFFFF;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 15px;
    }
} 
.borderless {
    border : 0px !important;
}
.main-content-wrapper {
    background: #822A64;
    min-height: 530px;
    background: url('/drawables/bmp.jpeg'); 
    background-size: cover;
}
.wel-box {  
    padding: 15px;
    border-radius: 9px; 
    margin-top: 12px;
}  
.white-bold {
    color: #FFFFFF;
    font-weight: bold; 
}    
.uform {
    text-align: left !important; 
    input, textarea, select, div, .ant-radio-button-wrapper {
        border-radius: 3px !important;
    }
    b{
        text-align: left;
    }
}  
.light-fancy-box {
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 5px;
    span {
        display: inline-block;
        padding: 6px 10px;
        background-color: #F96771; 
        font-weight : bolder;
        color: #FFFFFF;      
        transform: rotate(-5deg);
        -ms-transform: rotate(-7deg);
	    -webkit-transform: rotate(-7deg);
    }   
    button {
        background-color: #A990D9;
        color:#FFFFFF; 
        font-weight: bolder;
    }   
} 
.company-info-box{
    background: #5FCF85 !important;
    padding: 8px !important;    
}
.user-company-box { 
    padding: 8px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    margin: 18px 0px;
    border-radius: 6px;
    img {
        width: 90px !important;
        height: 60px !important;
        border-radius: 60px !important; 
    }
    .desc {
        background-color: #EFF2F5;
        color: #010101;         
        padding: 7px; 
        border-radius: 5px;
    } 
}
.tilted {
    transform: rotate(-3deg);
        -ms-transform: rotate(-3deg);
	    -webkit-transform: rotate(-3deg);
}
.ant-layout { background-color: @main-bkg; min-height: 600px;} 
.site-layout .site-layout-background {   
    background: @main-bkg;     
    min-height: 600px;
  }  
.top-ad-box { 
    background: @primary-color;   
    .arrow_box {
        position: relative;
        background: @primary-color;
    }
    .arrow_box:after {
        right: 100%;
        top: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(254, 73, 108, 0);
        border-right-color: @primary-color;
        border-width: 20px;
        margin-top: -20px;
    } 
  }
  .ant-layout-sider-trigger { 
      background-color: #7CA281 !important;
      color: #010101; 
  }  
  .event-box { 
      padding: 12px; 
      margin-top: 13px;
      border-radius: 4px; 
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      h5{ text-transform: uppercase;}
  } 
  .taskCol { 
    min-height: 500px;    
    border-radius: 0px 15px 15px 15px;    
  }  

  :where(.css-dev-only-do-not-override-1wazalj).ant-btn-primary { background-color: @primary-color;}
  .spad {
    border-radius: 0px 15px 0px 15px;
    min-height: 300px;
    background-color: @primary-color; 
    text-align: center;
    padding: 10px;
    color: #EEE;
  } 